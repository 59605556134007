// @ts-check
import { withinDateRange } from '../client/utils/withinDateRange';

/* host names */
export const tkCanonicalHost = 'https://www.theknot.com';
export const tkHost = 'https://qa-beta.theknot.com';
export const tkNext =
	'https://tk-marketplace-fe-nextjs-qa.k8s.localsolutions.theknot.com';

/* api keys */
export const geoApiKey = 'k7q2yqqbbad6a8ga8d3w8t5sga6tsfdnqex';
export const weddingsApiKey = 'b4j412nJcUmAFG3ChHKMM6f1m55L07f5';
export const membershipApiKey = 'c9G0v32i9uK1XU9Z756YDdiHps842muB';
export const analyticsWriteKey = '3a4gkKSM8GCrKz9iD6TuW9jlPgiv7WsM';
export const reviewsApiKey = 'nY8WZo4InKy5QXM5D6NXm6LgB1VfQ6mki9GLtwRI';
export const mediaApiKey = '40c732c5ac1944e06cd6b7371e9171b7';

/* endpoints */
export const bulkInquireAPIUrl =
	'https://qa-no-services.theknot.com/local-partners/api/conversation-theknot/conversations/bulkInquire';
export const conversationsApi =
	'https://qa-no-services.theknot.com/local-partners/api/conversation-theknot/conversations';
export const conversationsAuthApi =
	'https://qa-no-services.theknot.com/local-partners/api/app-auth/auth/member';
/* from George M. going forward there is no internal K8s url */
export const graphqlApiUrlK8 =
	'https://qa-marketplace-api.localsolutions.theknot.com/graphql';
export const graphqlApiUrl =
	'https://qa-marketplace-api.localsolutions.theknot.com/graphql';
export const inboxUrl = 'https://qa-beta.theknot.com/inbox/conversations';
export const inquireAPIUrl =
	'https://qa-no-services.theknot.com/local-partners/api/conversation-theknot/conversations/inquire';
export const mediaApiUrl = 'https://qa-media-api.xogrp.com/images/';
export const videoMediaApiUrl = 'https://qa-media-api.xogrp.com/videos/';
export const noServicesUrl = 'https://qa-no-services.theknot.com';
export const reviewsApiUrl =
	'https://qa-no-services.theknot.com/local-partners/api/reviews';
export const reviewAiSummaryUrl =
	'https://review-summaries.qa.dataops.nuptic.com/predict';
export const googleReviewsUrl =
	'https://pre-third-party-reviews-api-useast1.svc.nuptic.com/graphql';
export const transactionsAPIUrl =
	'https://qa-no-services.theknot.com/local-partners/api/transactions';
export const weddingsRootUrl = 'https://qa-api.weddings.xogrp.com';
export const taxonomyApi = 'https://api-qa.dataintel.xogrp.com/taxonomy/knot';
export const experimentAPI =
	'https://qa-api.experimentation.xogrp.com/experiments';
export const identityApi =
	'https://qa-identity-api.localsolutions.theknot.com/identities';
export const internalCreateImageReviewsUrl =
	'/marketplace/reviews/images/create';
export const internalDeleteImageReviewsUrl =
	'/marketplace/reviews/images/delete';
export const semanticSearchApiUrl =
	'https://semantic-search.dataops.nuptic.com/predict'; // There is no public QA endpoint for this service

export const gaTrackingId = 'UA-776588-94';

export const tkProUrl = 'https://partnerssignup.theknot.com';
export const vendorManagerUrl =
	'https://qa-beta.theknot.com/wedding-vendor-manager';

/* api keys */
export const pvRecApiKey = '59a8be0ca243409484c4d17cd5684027';

/* experiment API keys */
export const experimentAPIKey = 'bc9f3bf822ffa02707e172adda06cd44';

const unknownId = 'UNKNOWN_QA_ID';
/** @type Experiments.Settings */
export const experiments = {
	vrmMatchDetails: 'ebb4bb71-2a77-4c56-9102-47316cbb0dd9',
	reorderAllFilters: 'd8d0b69c-0234-447f-8f8e-7fe269f5fb34',
	tkNoContentBlurbs: 'ebbd9acd-78b4-4d45-afbe-069e8d58ab0d',
	refreshResults: 'f5847a93-c100-4f3e-b176-cfb93beb4ba2',
	betterImage: '0414bd78-3812-4b9c-8ce7-dfb04167cb33',
	newHeart: '627beb60-74cb-41a2-90f2-0acfe6189eff',
	mobileMapView: '9e14050e-706d-4149-aedb-550dda37ac77',
	quickResponder: '0349f035-d00d-4875-9c43-4abb156403f4',
	'pvrec2.0': '3d21dda8-7374-40c9-871a-cc8a3a593ea6',
	storefrontLightboxRevamp: 'ec60fa27-fbac-43f0-915b-3c8f4d242aee',
	liteRFQVendorWidget: '2def9641-86d2-4533-ad11-24d364b96e84',
	sortPill: '4ef1074c-2aa3-40b8-95b9-072004241b67',
	newStartingPrice: '13c082bf-51bd-4b0b-a694-fc440a8277bc',
	expectedValue: '75099504-872f-4411-a402-f6dcecfae85c',
	REC_VID_FLO_startingPrice: '4428fb0e-2077-4b03-a4b0-a981116c9cfb',
	recentlyViewed: '8deb0968-7955-4cf2-a1af-051f5cdf369e',
	VRMLegalUpdate: 'c84ab450-8d40-4fba-99c7-2a2d42472e98',
	VRMLegalUpdateMobile: '814c159a-50ad-4943-ba38-a1bef0c00108',
	semanticSearchV2: '3c897cf6-ad91-4242-a2b1-126088bb9bf5',
	vendorWidgetPlacement: '005ac6ed-bc4d-484f-a536-9a36dbb20585',
	rfqGuestCountFormat: 'd5302632-94f7-4677-aced-58b6fa60b9da',
	rfqMessageAssistance: '0b966aae-f58e-4c7b-a51e-0d41e7c4d004',
	emailDomainSuggester: 'e2e7c0db-4e6f-4d6c-afe3-13b6be71d4de',
	googleReviews: '90008871-787c-4e9a-8132-f13b1aa113ae',
	isNewBadge: 'd642e98f-6fef-40a7-982e-9f0b0ff2d356',
};

// If your new experiment is a backend experiment add it to this array
// This array of keys will be used to add the assignment when generating a hash key for search results
/** @type {string[]} */
export const backendExperimentKeys = [];

export const awards = {
	bow: {
		beforeStartDate: {
			year: '2024',
			name: '2024 Best of Weddings',
			filterId: '0b541473-2e05-47e6-8dc2-b635477785fc',
			filterSubText: 'Top Rated Vendors of the Year',
			slug: 'current-winner',
			iconId: 'd15e4891-5b15-4e3e-826e-623b5df9b552',
		},
		afterStartDate: {
			year: '2025',
			name: '2025 Best of Weddings',
			filterId: '586476df-c2fa-4c98-879d-87297163f8d1',
			filterSubText: 'Top Rated Vendors of the Year',
			slug: 'current-winner',
			iconId: '9f56c034-2057-4a02-99ee-18cc8c516ba7',
		},
	},
	hof: {
		beforeStartDate: {
			year: '2024',
			name: 'Hall of Fame',
			filterId: '19f33d4a-0a5a-48e2-af70-39f94ea4ea7f',
			filterSubText: 'Won Best of Weddings 4+ times',
			slug: 'hall-of-fame',
			iconId: '6738d190-bb59-4af8-bbe3-df752b1f6f51',
		},
		afterStartDate: {
			year: '2025',
			name: 'Hall of Fame',
			filterId: '19f33d4a-0a5a-48e2-af70-39f94ea4ea7f',
			filterSubText: 'Won Best of Weddings 4+ times',
			slug: 'hall-of-fame',
			iconId: '6738d190-bb59-4af8-bbe3-df752b1f6f51',
		},
	},
	endDate: 'January 15, 2026 00:00:00 EST',
	startDate: 'December 11, 2024 00:00:00 EST',
};

/* base API URL */
export const xoAPIRootUrl = 'https://qa-no-services.theknot.com';
export const marketplaceGraphqlUrl =
	'https://qa-marketplace-api.localsolutions.theknot.com/graphql';
export const xoAPIReviewsMemberUrl = `${xoAPIRootUrl}/local-partners/api/reviews/member`;
export const xoAPIReviewsStorefrontUrl = `${xoAPIRootUrl}/local-partners/api/reviews/storefront`;

export const googleMapsAPIKey = 'AIzaSyBv-OOatb5IbrZVE_VYqvpJrvrgPXUOme4';

export const bluecardRootUrl = 'https://qa.bluecard.theknot.com';

/* endpoints */
export const bluecardWeddingVisionExistsUrl = `${bluecardRootUrl}/api/v1/users/`;
export const bulkInquiryUrl = `${xoAPIRootUrl}/local-partners/api/conversation-theknot/conversations/bulkInquire`;

const BOW_CURRENT_WINNER_ID = withinDateRange({
	startDate: awards.startDate,
})
	? awards.bow.afterStartDate.filterId
	: awards.bow.beforeStartDate.filterId;

export const SearchConstants = {
	DEFAULT_LIMIT: 30,

	BOW_CURRENT_WINNER_ID,
	BOW_HALL_OF_FAME_ID: awards.hof.beforeStartDate.filterId,
	BOW_FILTER_SLUG: 'best-of-weddings',
	BOW_MEDAL_MEDIA_ID: 'b87d3db7-35c3-4a97-87db-1059c238c37d',

	INTERNAL_REFERRER_SORT: 'featured',
};

export const adSlotEnv = 'qa';

export const gpFeatureFlagEndpointUrl = 'https://pre1.weddingpro.com';

// After the availability feature goes to production on WeddingPro, this should be pre1.weddingwire.com
export const gpBaseUrl = 'https://pre1.weddingwire.com';
