const isResponseError = <T>(
	unknown: API.Response<T>,
): unknown is API.ErrorResponse<T> => {
	if ((unknown as API.ErrorResponse<T>).errors) {
		return true;
	}
	return false;
};

export default isResponseError;
