const IGNORE_ERROR_MESSAGES = ['chrome-extension://'];
const IGNORE_ERROR_ORIGINS = ['cookielaw.org'];

const errorCallerInfo = (error: Error) => {
	if (error instanceof Error) {
		// Extract caller information from the stack trace
		const { stack } = error || {};
		const stackLines = (stack || '').split('\n');
		if (stackLines.length > 1) {
			return stackLines[1].trim();
		}
	}
	return '';
};

const ignoreError = (error: Error) => {
	const callerInfo = errorCallerInfo(error);
	if (
		IGNORE_ERROR_MESSAGES.some((substring) =>
			error?.message?.includes(substring),
		) ||
		IGNORE_ERROR_ORIGINS.some((substring) => callerInfo.includes(substring))
	) {
		return true;
	}

	return false;
};

export const NewrelicHelpers = {
	ignoreError,
};
