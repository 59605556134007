export function largestRemainderMethod(
	numbers: Array<number>,
	desiredTotal: number,
) {
	const result = splitIntegerFromDecimal(numbers);
	const delta = calculateDelta(result, desiredTotal);

	for (let i = 0; i < delta; i += 1) {
		result[i].floor += 1;
	}

	return sortResultsByIndex(result);
}

function splitIntegerFromDecimal(numbers: Array<number>) {
	return numbers
		.map((number, index) => {
			return {
				floor: Math.floor(number),
				remainder: getRemainder(number),
				index,
			};
		})
		.sort((a, b) => {
			return b.remainder - a.remainder;
		});
}

function getRemainder(number: number) {
	const remainder = number - Math.floor(number);
	return Number(remainder.toFixed(4));
}

function calculateDelta(
	result: Array<{ floor: number }>,
	desiredTotal: number,
) {
	const lowerSum = result.reduce((sum, current) => {
		return sum + current.floor;
	}, 0);

	return desiredTotal - lowerSum;
}

function sortResultsByIndex(result: Array<{ floor: number; index: number }>) {
	return result
		.sort((a, b) => {
			return a.index - b.index;
		})
		.map(({ floor }) => {
			return floor;
		});
}
